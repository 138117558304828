.title{
    display: flex;
    flex-direction: row;
}

.title::before, .title::after{
    content: "";
    flex: 1 1;
    border-bottom: 2px solid red;
    margin: auto;
}
.title::before{
    margin-right: 30px;
}

.title::after{
    margin-left: 30px;
}

.course-title{
    font-weight: 700;
    text-decoration: underline;
    font-size: 22px;
    color: #515151;
}