.modal{
    display: none;
    position:fixed;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.892);
    background-color: rgba(0, 0, 0, 0.809);
}
.close{
    position:absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor:pointer;
}
.modal-content{
    display:block;
    
    background-color: white;
}